import request from '@/utils/request'

export function save(data) {
  return request({
    url: '/ltcloud/menu/save',
    method: 'post',
    data
  })
}

export function deteteData(data) {
  return request({
    url: '/ltcloud/menu/detete',
    method: 'post',
    params: data
  })
}

export function list() {
  return request({
    url: '/ltcloud/menu/list',
    method: 'get'
  })
}

export function menuList() {
  return request({
    url: '/ltcloud/menu/list',
    method: 'get'
  })
}

