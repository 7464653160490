<template>
  <div class="contain">
    <div id="app-contain">
      <div id="filter-container" class="filter-container">
        <el-input
          v-model="queryPage.name"
          placeholder="角色名称"
          style="width: 200px;"
          class="filter-item"
          clearable
        />
        <el-button class="filter-item" type="primary" icon="el-icon-search" @click="search()">查询</el-button>
        <el-button v-permission="['ROLE_ADD']" type="primary" icon="el-icon-plus" style="position:absolute;right:0" @click="handleAddRole">新增</el-button>
      </div>

      <el-table v-loading="listLoading" :data="rolesList" :height="tabheight" style="width: 100%;overflow:auto" border>
        <el-table-column align="center" label="序号" width="100" type="index" />
        <el-table-column label="操作" header-align="center" align="left" width="320" fixed="right">
          <template slot-scope="scope">
            <el-button v-permission="['ROLE_EDIT']" type="primary" size="small" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button type="primary" size="small" @click="handleMenu(scope.row)">分配菜单</el-button>
            <!-- <el-button type="primary" size="small" @click="handleAuth(scope.row)">分配权限</el-button> -->
          </template>
        </el-table-column>
        <el-table-column label="角色代码" header-align="center" align="left">
          <template slot-scope="scope">{{ scope.row.code }}</template>
        </el-table-column>
        <el-table-column label="角色名称" header-align="center" align="left">
          <template slot-scope="scope">{{ scope.row.name }}</template>
        </el-table-column>
        <el-table-column label="创建人员" header-align="center" align="left" width="200">
          <template slot-scope="scope">{{ scope.row.createUserName }}</template>
        </el-table-column>
        <el-table-column label="创建时间" header-align="center" align="right" width="200">
          <template slot-scope="scope">{{ scope.row.createTime }}</template>
        </el-table-column>
      </el-table>

      <pagination v-show="total>-1" id="pagination" :total="total" :page.sync="queryPage.page" :limit.sync="queryPage.limit" @pagination="loadList" />

      <el-dialog :visible.sync="dialogAddRoleVisible" :close-on-click-modal="false" :title="title" append-to-body width="600px">
        <el-form ref="form" :model="form" label-width="80px" label-position="left">
          <el-form-item v-if="!form.id" label="">
            <div slot="label">角色代码<font color="red">*</font></div>
            <el-input v-model="form.code" placeholder="角色代码" />
          </el-form-item>
          <el-form-item label="">
            <div slot="label">角色名称<font color="red">*</font></div>
            <el-input v-model="form.name" placeholder="角色名称" />
          </el-form-item>

        </el-form>
        <div style="text-align:center;">
          <el-button type="primary" @click="save">保存</el-button>
          <el-button type="danger" class="quxiao_btn" @click="dialogAddRoleVisible=false">取消</el-button>
        </div>
      </el-dialog>

      <el-dialog :visible.sync="dialogMenuiVisible" :title="'分配菜单'" :close-on-click-modal="false">

        <el-form label-width="80px" label-position="left">
          <el-form-item label="权限菜单">
            <el-tree
              ref="tree"
              :data="menuList"
              show-checkbox
              default-expand-all
              node-key="id"
              highlight-current
              :props="defaultProps"
            />
          </el-form-item>
        </el-form>
        <div style="text-align:center;">
          <el-button type="primary" @click="saveMenu">保存</el-button>
          <el-button type="danger" class="quxiao_btn" @click="dialogMenuiVisible=false">取消</el-button>
        </div>
      </el-dialog>


      <el-dialog :visible.sync="dialogAuthVisible" :title="'分配权限'" :close-on-click-modal="false">

        <el-table
          ref="multipleTable"
          :data="aurhList"
          border
          tooltip-effect="dark"
          style="width: 100%"
          size="mini"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="40" />
          <el-table-column prop="name" label="权限名称" width="200" />
          <el-table-column prop="code" label="权限code" width="200" />
          <el-table-column prop="menuName" label="所属菜单" />
        </el-table>

        <div style="text-align:center;">
          <el-button type="primary" @click="saveAuth">保存</el-button>
          <el-button type="danger" class="quxiao_btn" @click="dialogAuthVisible=false">取消</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { addRole, getList, getRoleMenuList, saveMenu } from '@/api/role'
import { menuList } from '@/api/menu'
import { Message } from 'element-ui'
import { tableHeight2 } from '@/utils/tableHeight'
import Pagination from '@/components/Pagination'

export default {
  name: 'Jsgl',
  components: { Pagination },
  mixins: [tableHeight2],
  data() {
    return {
      tabheight: 0,
      departvalue: '',
      options: {
        value: 'id',
        label: 'name',
        children: 'children',
        expandTrigger: 'hover',
        checkStrictly: true
      },
      listLoading: false,
      total: 0,
      queryPage: {
        page: 1,
        limit: 20,
        name: '',
        departmentname: null
      },
      rolesList: [],
      yyList: [],
      dialogAddRoleVisible: false,
      flag: '',
      title: '',
      form: {
        id: '',
        name: '',
        code: ''
      },
      dialogMenuiVisible: false,
      menuList: [],
      selectRow: null,
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      dialogAuthVisible: false,
      aurhList: [],
      hashAuthId: [],
      multipleSelection: [],
      loadingItem: false,
      itemList: [],
      selectItems: []
    }
  },
  mounted() {
    this.$nextTick(function() {
      var filterHeight = document.getElementById('filter-container').offsetHeight
      var tableHeight = document.getElementById('app-contain').offsetHeight
      var pagination = document.getElementById('pagination').offsetHeight
      this.tabheight = tableHeight - filterHeight - pagination
      console.log(this.tabheight, filterHeight, tableHeight, pagination)
      window.onresize = () => {
        return (() => {
          var filterHeight = document.getElementById('filter-container').offsetHeight
          var tableHeight = document.getElementById('app-contain').offsetHeight
          var pagination = document.getElementById('pagination').offsetHeight
          this.tabheight = tableHeight - filterHeight - pagination
        })()
      }
    })
  },
  created() {
    this.loadList()
  },
  methods: {
    handleAddRole() {
      this.form = {
        id: '',
        name: '',
        code: ''
      }
      this.title = '新增角色'
      this.dialogAddRoleVisible = true
    },
    handleEdit(row) {
      this.form = {}
      this.flag = 1
      this.form.id = row.id
      this.form.code = row.code
      this.form.name = row.name

      this.title = '编辑角色'
      this.dialogAddRoleVisible = true
    },
    async save() {
      this.flag = ''
      if (!this.form.code) {
        Message({
          type: 'error',
          message: '角色代码不能为空'
        })
        return
      } else if (!this.form.name) {
        Message({
          type: 'error',
          message: '角色名称不能为空'
        })
        return
      } else {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })

        addRole(this.form).then(response => {
          Message({
            message: '操作成功',
            type: 'success',
            duration: 5 * 1000
          })
          loading.close()
          this.dialogAddRoleVisible = false
          // 重新加载表格
          this.loadList()
        }).catch(response => {
          loading.close()
        })
      }
    },
    search() { // 查询
      this.loadList()
    },
    loadList() { // 加载列表
      this.rolesList = []
      this.listLoading = true
      getList(this.queryPage).then(response => {
        const { data } = response
        this.rolesList = data.records

        this.total = parseInt(data.total)
        this.listLoading = false
      }).catch(response => {
        this.listLoading = false
      })
    },
    handleMenu(row) {
      this.selectRow = row
      this.dialogMenuiVisible = true

      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      menuList().then(response => {
        var data = response.data
        var list = []

        for (let i = 0; i < data.length; i++) {
          var e = data[i]
          if (e.parentId === '0') {
            list.push(e)
          }

          for (let j = 0; j < data.length; j++) {
            const e1 = data[j]
            if (e1.parentId === e.id) {
              var children = e.children
              if (!children) {
                children = []
                e.children = children
              }
              children.push(e1)
            }
          }
        }
        this.menuList = list
        getRoleMenuList({ roleId: row.id }).then(response => {
          var data = response.data
          var list = []
          data.forEach(e => {
            list.push(e.menuId)
          })
          this.$refs.tree.setCheckedKeys(list)
          loading.close()
        }).catch(response => {
          loading.close()
        })
      }).catch(response => {
        loading.close()
      })
    },
    saveMenu() {
      var menuIds = this.$refs.tree.getCheckedKeys()
      if (menuIds.length === 0) {
        Message({
          message: '请选择菜单',
          type: 'error',
          duration: 5 * 1000
        })
        return
      }

      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      saveMenu({ roleId: this.selectRow.id, menuIds: menuIds }).then(response => {
        Message({
          message: '操作成功',
          type: 'success',
          duration: 5 * 1000
        })
        loading.close()
        this.dialogMenuiVisible = false
        // 重新加载表格
        this.loadList()
      }).catch(response => {
        loading.close()
      })
    },
    handleAuth(row) {
      this.selectRow = row
      this.dialogAuthVisible = true
      this.aurhList = []

      // const loading = this.$loading({
      //   lock: true,
      //   text: 'Loading',
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(0, 0, 0, 0.7)'
      // })

      // getRoleAuthorit({ roleId: row.id }).then(response => {
      //   this.aurhList = response.data.list
      //   this.hashAuthId = response.data.hashAuthId
      //   this.$nextTick(() => {
      //     this.rowSelection()
      //   })
      //   loading.close()
      // }).catch(response => {
      //   loading.close()
      // })
    },
    rowSelection() {
      var aurhList = this.aurhList
      var hashAuthId = this.hashAuthId
      for (let i = 0; i < aurhList.length; i++) {
        const row = aurhList[i]
        if (hashAuthId) {
          for (let j = 0; j < hashAuthId.length; j++) {
            const id = hashAuthId[j]
            if (row.id === id) {
              this.$refs.multipleTable.toggleRowSelection(row, true)
            }
          }
        }
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    saveAuth() {
      // const loading = this.$loading({
      //   lock: true,
      //   text: 'Loading',
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(0, 0, 0, 0.7)'
      // })
      var data = this.multipleSelection
      var roleAuthoritys = []
      data.forEach(e => {
        var item = {}
        item.authorityId = e.id
        item.authorityCode = e.code
        roleAuthoritys.push(item)
      })

      // saveAuthorit({ roleId: this.selectRow.id, roleAuthoritys: roleAuthoritys }).then(response => {
      //   Message({
      //     message: '操作成功',
      //     type: 'success',
      //     duration: 5 * 1000
      //   })
      //   loading.close()
      //   this.dialogAuthVisible = false
      //   // 重新加载表格
      //   this.loadList()
      // }).catch(response => {
      //   loading.close()
      // })
    }
  }
}
</script>
<style lang="less" scoped>
.contain{
    height:calc(100% - 20px);
    width:calc(100% - 20px);
    padding:10px 10px;
}
#app-contain{
    height:100%;
    width:100%
}
.filter-container {
    position:relative;

  .filter-item {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    margin-bottom:10px;
  }
}
.pagination-container{
    margin-top:0 !important;
    padding: 10px 16px !important;
}

</style>

<style lang="scss" scoped>
</style>
